/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Button from '../components/Button';
import Icon from '../components/Icon';
import { MainLayout } from '../components/Layout';
import { Container } from '../components/LayoutComp';
import SEO from '../components/SEO';
import withI18next from '../components/withI18next';
import { isBrowser } from '../utils';
import getParamFromQueryString from '../utils/getParamFromQueryString';

const Main = styled.article`
  .partTop {
    padding-top: 2em;
  }
  .partStructure {
    background-color: #f2f2f2;
  }
  .partStructure,
  .partKey {
    padding-top: 2.5em;
    padding-bottom: 3em;
  }
  .title {
    font-size: 2rem;
    margin-bottom: 0.5em;
    margin-top: 0;
    em {
      color: #d22222;
      font-style: normal;
      display: block;
    }
  }

  @media only screen and (min-width: 922px) {
    .partTop {
      padding-top: 5em;
      padding-bottom: 5em;
    }
    .title {
      font-size: 2.5rem;
      em {
        display: inline-block;
      }
    }
  }
`;

const Purpose = styled.div`
  .content {
    max-width: 560px;
    margin-bottom: 0;
    padding-left: 1em;
    list-style: none;
    li {
      &:before {
        content: '◆';
        color: #d22222;
        width: 1.5em;
        margin-left: -1.5em;
        display: inline-block;
        text-align: center;
      }
      margin-bottom: 0.75em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .img {
    margin-top: 2em;
    margin-left: -20px;
    margin-right: -20px;
  }

  @media only screen and (min-width: 922px) {
    position: relative;

    .img {
      margin-top: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40%;
      max-width: 560px;
      margin-left: 0;
      margin-right: 0;
      /* the margin bottom of p tag is 0.725 + padding of parent element */
      margin-bottom: -5em;
    }
  }
`;

const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 1em;

  &.center {
    max-width: 24em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}` : ''};

  em {
    color: #d22222;
    font-style: normal;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const StructureList = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
  }
`;

const StructureItem = styled.div`
  padding: 1.5em;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 1em;

  @media only screen and (min-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    flex: 0 calc(50% - 20px);
  }

  @media only screen and (min-width: 922px) {
    flex: 0 calc(25% - 20px);
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    .icon {
      width: 26px;
      height: 26px;
      border-radius: 100%;
      background-color: #d22222;
      margin-right: 8px;
      color: white;
      padding: 2px 5px;
    }
    .title {
      font-size: 1em;
      margin: 0;
    }
  }
  .body {
    font-size: 14px;
  }
`;

const KeyList = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
`;

const KeyItem = styled.div`
  margin-bottom: 2em;

  @media only screen and (min-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    flex: 0 calc(50% - 30px);

    &:first-child {
      margin-right: 20px;
    }
    &:last-child {
      margin-left: 20px;
    }
  }

  @media only screen and (min-width: 922px) {
    flex: 0 calc(50% - 50px);
    &:first-child {
      margin-right: 40px;
    }
    &:last-child {
      margin-left: 40px;
    }
  }

  &:before {
    content: '';
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #d22222;
    display: block;
    margin-bottom: 1em;
  }
`;

const ActionWrapper = styled.div`
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

const EntranceTest = ({ location, pageContext }) => {
  const testId = getParamFromQueryString(location.search, 'testId');
  const { lmsTestLink = '/:testId' } = pageContext;
  const targetTestLink = lmsTestLink.replace(':testId', testId);

  return (
    <StaticQuery
      query={graphql`
        query {
          girlInWhite: file(relativePath: { eq: "test/entrance2.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
    >
      {data => (
        <MainLayout hideReview>
          <SEO
            title="Entrance Test 02!"
            description="Giúp học viên hiểu được trình độ hiện tại của bản thân, từ đó chọn được khoá học phù hợp nhất với trình độ của mình."
            meta={pageContext.meta}
          />
          <Main>
            <section className="partTop">
              <Container>
                <h1 className="title">
                  Welcome to <em>Entrance Test 02!</em>
                </h1>
                <div>
                  <SubTitle>Mục đích bài test:</SubTitle>
                  <Purpose>
                    <ul className="content">
                      <li>
                        Giúp học viên hiểu được trình độ hiện tại của bản thân,
                        từ đó chọn được khoá học phù hợp nhất với trình độ của
                        mình.
                      </li>
                      <li>
                        Giúp giáo viên đứng lớp phân tích được điểm mạnh và điểm
                        yếu của từng học viên, từ đó thiết kệ được lộ trình học
                        cá nhân (personal study plan) phù hợp để giúp học viên
                        cải thiện trong thời gian ngắn nhất.
                      </li>
                      <li>
                        Giúp giáo viên đứng lớp nắm được liệu học viên đã từng
                        làm quen với format kì thi IELTS hay chưa, từ đó điều
                        chỉnh và đưa ra những chiến thuật làm bài phù hợp cho
                        học viên.
                      </li>
                    </ul>
                    <div className="img">
                      <Img
                        fluid={data.girlInWhite.childImageSharp.fluid}
                        alt="Dolenglish - entrance test"
                      />
                    </div>
                  </Purpose>
                </div>
              </Container>
            </section>

            <section className="partStructure">
              <Container>
                <SubTitle marginBottom="1.5em" className="center">
                  Cấu trúc bài test gồm 4 phần:
                </SubTitle>
                <StructureList>
                  <StructureItem>
                    <div className="header">
                      <div className="icon">
                        <Icon type="Headphone" />
                      </div>
                      <h6 className="title">Listening</h6>
                    </div>
                    <div className="body">
                      Học viên làm thử 1 bài full test tương tự như bài thi thật
                      trong Listening. Học viên chỉ được nghe đoạn audio 1 lần.
                    </div>
                  </StructureItem>
                  <StructureItem>
                    <div className="header">
                      <div className="icon">
                        <Icon type="Book" />
                      </div>
                      <h6 className="title">Reading</h6>
                    </div>
                    <div className="body">
                      Học viên hoàn thành 1 full IELTS Reading test gồm 3
                      passages trong vòng 60 phút.
                    </div>
                  </StructureItem>
                  <StructureItem>
                    <div className="header">
                      <div className="icon">
                        <Icon type="Pen" />
                      </div>
                      <h6 className="title">Writing</h6>
                    </div>
                    <div className="body">
                      Học viên viết 1 bài essay Task 2 hoàn chỉnh (ít nhất 250
                      từ) trả lời một đề bài nghị luận cho sẵn.
                    </div>
                  </StructureItem>
                  <StructureItem>
                    <div className="header">
                      <div className="icon">
                        <Icon type="Chat" />
                      </div>
                      <h6 className="title">Speaking</h6>
                    </div>
                    <div className="body">
                      Học viên ghi âm lại phần trả lời của mình cho part 1, part
                      2 và part 3 trong kì thi IELTS Speaking.
                    </div>
                  </StructureItem>
                </StructureList>
              </Container>
            </section>

            <section className="partKey">
              <Container>
                <SubTitle marginBottom="1.5em" className="center">
                  <em>Điểm khác biệt lớn nhất của bài test: </em>
                  <br />
                  chính là việc bài test phù hợp với trình độ hiện tại của học
                  sinh level thấp.
                </SubTitle>
                <KeyList>
                  <KeyItem>
                    <p>
                      Bài test được chọn lọc để xác định chính xác nhất trình độ
                      của học viên. Ví dụ, những passage được lựa chọn trong bài
                      test Reading bao gồm nhiều dạng câu hỏi khác nhau nhằm
                      kiểm tra khả năng đọc hiểu cả main idea và detail của học
                      viên, từ đó xác định chính xác dạng câu hỏi học viên còn
                      yếu.
                    </p>
                  </KeyItem>
                  <KeyItem>
                    <p>
                      Tương tự, đề bài Writing được chọn là một đề có độ khó
                      trung bình và khá quen thuộc với nhiều học viên. Điều này
                      giúp hạn chế tình trạng học viên không viết được vì thiếu
                      ideas, và cho phép giáo viên phân tích rõ hơn khả năng
                      ngôn ngữ (từ vựng, ngữ pháp, cách phát triển ý) của học
                      viên.
                    </p>
                  </KeyItem>
                </KeyList>

                <ActionWrapper>
                  {isBrowser() && (
                    <Button to={targetTestLink} target="_blank">
                      Đăng nhập để làm bài
                    </Button>
                  )}
                </ActionWrapper>
              </Container>
            </section>
          </Main>
        </MainLayout>
      )}
    </StaticQuery>
  );
};

EntranceTest.propTypes = {
  location: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired
};

export default withI18next()(EntranceTest);
